import * as React from "react";
import { Link } from "gatsby";
import * as styles from "./SingleArticleCard.module.scss";
import slugify from "slugify";
import article4Image from "@images/index_page/article_placeholders/hacker4.jpeg";

let options = {
  // weekday: "long",
  year: "numeric",
  month: "long",
  day: "numeric"
};

const SingleArticleCard = ({
  i,
  title,
  tags,
  staff,
  created,
  header,
  id,
  image,
  noAuthor
}) => {
  const slug = slugify(title, { lower: true });

  return (
    <Link to={`/articles/${slug}`} className={styles.single_article_card}>
      <article
        key={i}
        className={styles.article}
        style={{ backgroundImage: `url(${image || article4Image})` }}
      >
        <div className={styles.gradient}></div>
        <h3>{title}</h3>
      </article>
      {!noAuthor && (
        <p className={styles.mili}>
          {(staff && staff.length > 0 && staff[0] && staff[0].name) || "Peter Antley"} -{" "}
          {new Date(created).toLocaleDateString("en-US", options)}
        </p>
      )}
    </Link>
  );
};

export default SingleArticleCard;
