import * as React from "react";
import { useState, useEffect } from "react";
import Insights from "@components/Home/Insights";
import ArticleContent from "./ArticleContent";
import ArticleHeader from "./ArticleHeader";
import AuthorCard from "./AuthorCard";
import ContactForm from "../ContactForm";
import * as styles from "./SingleArticle.module.scss";

import DataService from "@utils/DataService";

const author = {
  name: "Peter Antley",
  role: "Co-founder",
  image: "",
  tags: ["DataScience", "Automation", "Business"]
};

const SingleArticleComponent = article => {

  console.log(article)
  return (
    <>
      <div className={styles.article}>
        <ArticleHeader {...article} />
        <div className={`${styles.article_columns} container`}>
          <ArticleContent content={article.content} />
          <section className={styles.more}>
            <AuthorCard {...article} />
            <h2 style={{ marginTop: "80px" }}>More Like This</h2>
            <Insights />
          </section>
        </div>
      </div>
      <section className="container">
        <ContactForm />
      </section>
    </>
  );
};

export default SingleArticleComponent;
