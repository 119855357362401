import * as React from "react";
import * as styles from "../SingleArticle.module.scss";

import article1Image from "@images/index_page/article_placeholders/hacker1.jpg";

const options = {
  // weekday: "long",
  year: "numeric",
  month: "long",
  day: "numeric"
};

const ArticleContent = ({ title, header, created }) => {

  const articleHeaderStyle = {
    backgroundImage: `url(${article1Image})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    minHeight: "30vh",
    width: "100%"
  };

  return (
    <div className={styles.article}>
      <div style={articleHeaderStyle} className={`${styles.article_header} container`}>
        <div className={`${styles.header_text} container`}>
          <h1 className={title}>{title}</h1>
          <h2 className={title}>{header}</h2>
          <h3>Posted on {new Date(created).toLocaleDateString("en-US", options)}</h3>
        </div>
      </div>
    </div>
  );
};

export default ArticleContent;
