import * as React from "react";
import SingleArticleComponent from "@components/SingleArticle";
import Layout from "@components/layout";
import { graphql } from "gatsby";
import SEO from "@components/seo";


const ArticlePage = ({ data }) => {
  const article = data.articles;
  return (
    <>
      <Layout>
        <SEO title={`Article: ${article.title}`} />
        <SingleArticleComponent {...article} />
      </Layout>
    </>
  );
};

export const query = graphql`
  query getSingleArticle($title: String) {
    articles(title: { eq: $title }) {
      title
      id
      staff {
        id,
        name,
        position,
        picture {
          name,
          image
        }
      }
      content
      created
      header
      image
      tags {
        name
      }
    }
  }
`;

export default ArticlePage;
