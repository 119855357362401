import * as React from "react";
import * as styles from "../SingleArticle.module.scss";

const ArticleContent = props => {
  const { content } = props;

  function createMarkup() {
    return {
      __html: content
    };
  }

  return (
    <div className={styles.article}>
      <div className={styles.article_body}>
        <article className={styles.article_content}>
          <h2>Article Subtitle 1</h2>
          <div dangerouslySetInnerHTML={createMarkup()} />
        </article>
      </div>
    </div>
  );
};

export default ArticleContent;
