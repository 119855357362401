// extracted by mini-css-extract-plugin
export const article = "SingleArticle-module--article--HpgZ8";
export const article_header = "SingleArticle-module--article_header--x9u3C";
export const header_text = "SingleArticle-module--header_text--34ezy";
export const article_content = "SingleArticle-module--article_content--xMx3l";
export const author_card = "SingleArticle-module--author_card--1tiht";
export const tags = "SingleArticle-module--tags--AGaGz";
export const author_img = "SingleArticle-module--author_img--1xk31";
export const article_columns = "SingleArticle-module--article_columns--2dJPh";
export const bouncy = "SingleArticle-module--bouncy--hM_hc";
export const pop = "SingleArticle-module--pop--3lsNG";