import * as React from "react";
import { useStaticQuery, graphql } from "gatsby";
import SingleArticleCard from "@components/Articles/SingleArticleCard";
import * as styles from "./Insights.module.scss";

const Insights = ({ grid }) => {

  const data = useStaticQuery(graphql`
    {
      allArticles(limit: 4) {
        nodes {
          id
          staff {
            id,
            name,
            position,
            picture {
              name,
              image
            }
          }
          created
          header
          image
          content
          title
        }
      }
    }
  `);

  const articlesData = data.allArticles.nodes;

  return (
    <div className={styles.insights}>
      <section
        className={`${styles.articles_container} ${
          grid ? styles.articles_grid : ""
        }`}
      >
        {articlesData?.length > 0 &&
          articlesData.map((article, i) => <SingleArticleCard {...article} />)}
      </section>
    </div>
  );
};

export default Insights;
