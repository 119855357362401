import * as React from "react";
import * as styles from "../SingleArticle.module.scss";


const AuthorCard = ({author, tags, image}) => {

  return (
    <div className={styles.author_card}>
      {image ? <img src={image} className={styles.author_img} alt="Author" /> : <span className={styles.author_img} /> }
      <h3>{author ? author.name : 'Peter Antley'}</h3>
      <p>Co-founder</p>
      <div className={styles.tags}>
        {tags?.length>0 && tags.map(tag => (
          <span className={styles.tag}  key={tag.name} >{tag.name} </span>
        ))}
      </div>
    </div>
  );
};

export default AuthorCard;